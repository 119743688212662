<script lang="ts">
  export let that
  export let position: "left" | "right" | "bottom" | null = null
</script>

<div
  bind:this={that}
  class="
  transition-all duration-300 fixed z-50 w-full h-full
  {position === 'bottom' ? 'mx-auto inset-x-0 top-full rounded-t-lg' : ''} 
  {position === 'right' ? 'top-0 -right-full rounded-l-lg' : ''} 
  overflow-y-auto bg-gray-100 dark:bg-[#111] max-w-xl {$$props.class}"
>
  <div class="h-full overflow-y-auto pt-6 pb-10">
    <slot />
  </div>
</div>
