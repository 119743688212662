<script lang="ts">
  import { elements, party } from "src/plugins/store"
  import emitter from "src/plugins/emitter"
  import currency from "src/utils/currency"
  import List from "src/lib/List.svelte"
  import Divider from "src/lib/Divider.svelte"
  import Overlay from "src/lib/Overlay.svelte"
  import Sheet from "src/lib/Sheet.svelte"
  import Bar from "src/lib/Bar.svelte"
  import { _ } from "svelte-i18n"

  export let partyMemberName: string = ""

  function init(value) {
    partyMemberName = value
  }

  function dutchPay(bill, member) {
    return (
      (bill.amount -
        bill.members.reduce((acc, cur) => {
          return (acc += cur.fixed || 0)
        }, 0)) /
      bill.members.filter(
        (member) => member.fixed === null && member.attend === true
      ).length
    )
  }

  function debts(partyMemberName) {
    const debts = $party.members.reduce((acc, member) => {
      acc[member.name] = 0
      return acc
    }, {})

    for (const bill of $party.bills) {
      // 낸사람 계산
      bill.members.forEach((member) => {
        if (member.amount > 0) debts[member.name] -= Number(member.amount)
      })

      // 고정 지출
      const fixedPayers = bill.members.filter((member) => member.fixed > 0)
      let sumOfFixedAmounts = 0
      fixedPayers.forEach((member) => {
        debts[member.name] += Number(member.fixed)
        sumOfFixedAmounts += Number(member.fixed)
      })

      // 참석자 계산
      const attendees = bill.members.filter(
        (member) => member.attend && member.fixed <= 0
      )
      const amount =
        (Number(bill.amount) - sumOfFixedAmounts) / attendees.length
      attendees.forEach((member) => {
        debts[member.name] += amount
      })
    }

    return debts[partyMemberName]
  }

  emitter.on("openSheetLog", (value) => open(value))
  function open(value) {
    init(value)
    // $elements.layoutTop.classList.replace("bg-white", "bg-gray-400")
    // $elements.app.classList.replace("bg-white", "bg-gray-400")
    $elements.sheetLog.classList.replace("top-full", "top-[5%]")
    $elements.overlayLog.classList.replace("bg-opacity-0", "bg-opacity-50")
    $elements.overlayLog.classList.replace(
      "pointer-events-none",
      "pointer-events-auto"
    )
    $elements.app.classList.toggle("scale-95")
  }

  function close() {
    // $elements.layoutTop.classList.replace("bg-gray-400", "bg-white")
    // $elements.app.classList.replace("bg-gray-400", "bg-white")
    $elements.sheetLog.classList.replace("top-[5%]", "top-full")
    $elements.overlayLog.classList.replace("bg-opacity-50", "bg-opacity-0")
    $elements.overlayLog.classList.replace(
      "pointer-events-auto",
      "pointer-events-none"
    )
    $elements.app.classList.toggle("scale-95")
  }
</script>

<Overlay bind:that={$elements.overlayLog} on:click={close} />

<Sheet bind:that={$elements.sheetLog} position="bottom">
  <!-- TOP -->
  <Bar>
    <div slot="left" class="text-blue-500 cursor-pointer" on:click={close}>
      {$_("button.cancel")}
    </div>
    <div slot="center">{partyMemberName}</div>
  </Bar>

  <List>
    {#each $party.bills as bill, index}
      {#each bill.members as member}
        {#if member.name === partyMemberName && (member.amount !== 0 || member.fixed || (member.attend && dutchPay(bill, member) !== 0))}
          <div>
            <span class="font-semibold">{bill.title}</span>
          </div>
          {#if member.amount !== 0}
            <div>
              <span class="text-green-500">
                {$_("log.have_to_receive", {
                  values: { amount: currency(member.amount) },
                })}
              </span>
            </div>
          {/if}
          {#if member.fixed || member.attend & (dutchPay(bill, member) !== 0)}
            <div>
              <span class="text-red-500">
                {$_("log.have_to_pay", {
                  values: {
                    amount: currency(member.fixed || dutchPay(bill, member)),
                  },
                })}
              </span>
            </div>
          {/if}
          {#if index !== $party.bills.length - 1}
            <Divider />
          {/if}
        {/if}
      {/each}
    {/each}
  </List>

  <List>
    <div class="flex justify-between">
      <div>{$_("log.sum")}</div>
      <div
        class:text-red-500={debts(partyMemberName) > 0}
        class:text-green-500={debts(partyMemberName) < 0}
      >
        {debts(partyMemberName) > 0
          ? $_("log.have_to_pay", {
              values: { amount: currency(Math.abs(debts(partyMemberName))) },
            })
          : ""}
        {debts(partyMemberName) < 0
          ? $_("log.have_to_receive", {
              values: { amount: currency(Math.abs(debts(partyMemberName))) },
            })
          : ""}
      </div>
    </div>
  </List>
</Sheet>
