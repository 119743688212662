<script lang="ts">
  import Overlay from "src/lib/Overlay.svelte"
  import Sheet from "src/lib/Sheet.svelte"
  import Bar from "src/lib/Bar.svelte"
  import List from "src/lib/List.svelte"
  import emitter from "src/plugins/emitter"

  import { elements, party } from "src/plugins/store"
  import { compress } from "src/utils/hampress"
  import { _ } from "svelte-i18n"

  function shareReadable() {
    if ($party.bills.length === 0 || $party.members.length === 0) {
      close()
      return emitter.emit("alert", {
        text: $_("bottom.phrase_check_out_tasks"),
      })
    }
    const text = window.location.href + `?p=${compress($party)}`
    navigator.clipboard
      .writeText(text)
      .then(() => {
        emitter.emit("alert", { text: $_("bottom.phrase_clipboard_success") })
        close()
      })
      .catch(() => {
        emitter.emit("alert", { text: $_("bottom.phrase_clipboard_fail") })
        close()
      })
  }

  function shareWritable() {
    close()
    emitter.emit("alert", { text: "미지원 기능입니다." })
  }

  emitter.on("openSheetShare", () => open())
  function open() {
    $elements.sheetShare.classList.replace("-right-full", "right-0")
    $elements.sheetShare.classList.replace("-bottom-full", "bottom-0")
    $elements.overlayShare.classList.replace(
      "pointer-events-none",
      "pointer-events-auto"
    )
  }

  emitter.on("closeSheetShare", () => close())
  function close() {
    $elements.sheetShare.classList.replace("right-0", "-right-full")
    $elements.sheetShare.classList.replace("bottom-0", "-bottom-full")
    $elements.overlayShare.classList.replace(
      "pointer-events-auto",
      "pointer-events-none"
    )
  }
</script>

<Overlay bind:that={$elements.overlayShare} on:click={close} />
<div
  bind:this={$elements.sheetShare}
  class="transition-all duration-300 fixed z-50 -right-full -bottom-full w-auto h-auto mb-7 mr-1 p-1 rounded-lg"
>
  <div
    class="rounded-lg flex flex-col space-y-6 py-4 px-4 bg-gray-100 dark:bg-[#111] mx-0"
  >
    <div class="cursor-pointer" on:click={shareReadable}>읽기 권한 공유</div>
    <div class="cursor-not-allowed" on:click={shareWritable}>
      편집 권한 공유
    </div>
  </div>
</div>
