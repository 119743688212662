// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA4IbySiIsxi1na9_R_HA2wIADZoyganA8",
    authDomain: "fb-hampay.firebaseapp.com",
    databaseURL: "https://fb-hampay-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "fb-hampay",
    storageBucket: "fb-hampay.appspot.com",
    messagingSenderId: "232134230745",
    appId: "1:232134230745:web:bc0aa23f9ec783eebef4c8",
    measurementId: "G-3ZS9QMVQ1M",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
