import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";
import en from "src/i18n/en.json";
import ko from "src/i18n/ko.json";
import tok from "src/i18n/tok.json";
addMessages("en", en);
addMessages("ko", ko);
addMessages("tok", tok);
init({
    fallbackLocale: "en",
    initialLocale: getLocaleFromNavigator(),
});
export const languages = [
    { display: "English", value: "en" },
    { display: "한국어", value: "ko-KR" },
    { display: "toki pona", value: "tok" },
];
export const currencies = [
    { display: "NONE", symbol: "", value: "none", languages: "" },
    { display: "USD", symbol: "$", value: "usd", languages: "en" },
    { display: "KRW", symbol: "₩", value: "krw", languages: "ko-KR" },
];
