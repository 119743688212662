<script lang="ts">
  import { party, result } from "src/plugins/store"
  import Icon from "src/lib/Icon.svelte"
  import MainBills from "./MainBills.svelte"
  import MainResult from "./MainResult.svelte"
  import MainLog from "src/components/MainLog.svelte"
  import Button from "src/lib/Button.svelte"
  import { _ } from "svelte-i18n"
  import emitter from "src/plugins/emitter"
</script>

<div
  class="trainstition-all duration-500 container mx-auto pt-3 px-6 sm:px-8 md:px-10 lg:px-12"
>
  <h2 class="keep-all">
    {$_("main.title", { values: { title: $party.title } })}
  </h2>

  {#if $party.members.length === 0}
    <div class="my-8" />
    <!-- TODO: i18n -->
    <h3 class="keep-all">우선 파티 제목을 수정하고, 멤버를 추가해 볼까요?</h3>
    <div class="my-8" />
    <Button on:click={() => emitter.emit("openSheetParty")}>
      {$_("main.tutorial.addMembers")}
    </Button>
  {:else if $party.bills.length === 0}
    <div class="my-8" />
    <!-- TODO: i18n -->
    <h3 class="keep-all">거의 다 왔습니다! 이번엔 영수증을 추가해 볼까요?</h3>
    <div class="my-8" />
    <Button on:click={() => emitter.emit("openSheetBill")}
      >{$_("main.tutorial.addBill")}</Button
    >
  {/if}

  {#if $result.length !== 0}
    <h3 class="mt-6">{$_("main.result_header")}</h3>
    <MainResult />
  {/if}
  {#if $party.bills.length !== 0}
    <h3>{$_("main.bill_header")}</h3>
    <MainBills />
  {/if}
  <!-- <h3>다음엔 이런 여행 어때요?</h3>
  <div class="box">여행 광고</div>
  <div class="box">여행 광고</div>
  <div class="box">여행 광고</div> -->
  <div class="my-28" />
</div>
