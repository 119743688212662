<script type="ts">
  import "external-svg-loader"

  export let name = ""
  export let text = ""
  export let fill = ""
  export let pointer = false
  export let disabled = false
</script>

<div class="place-self-center" on:click on:mousedown>
  <svg
    class="w-8 h-8 place-self-center self-center {$$props.class}"
    class:fill-current={fill === ""}
    {fill}
    class:cursor-pointer={pointer}
    class:disabled:opacity-30={disabled}
    data-src="assets/md-icons/{name}.svg"
  />
  {#if text}
    <span>
      {text}
    </span>
  {/if}
</div>
