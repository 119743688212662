<div
  class="absolute top-0 z-10 w-full h-12 grid grid-cols-12 px-3 pt-3 bg-gray-100 dark:bg-[#111] {$$props.class}"
>
  <div class="text-left col-span-3">
    <slot name="left" />
  </div>
  <div class="text-center col-span-6">
    <slot name="center" />
  </div>
  <div class="text-right col-span-3">
    <slot name="right" />
  </div>
</div>
