<script lang="ts">
  import Main from "src/components/Main.svelte"
  import LayoutTop from "src/components/LayoutTop.svelte"
  import LayoutBottom from "src/components/LayoutBottom.svelte"
  import SheetParties from "src/components/SheetParties.svelte"
  import SheetParty from "src/components/SheetParty.svelte"
  import SheetBill from "src/components/SheetBill.svelte"
  import SheetLog from "src/components/SheetLog.svelte"
  import SheetShare from "src/components/SheetShare.svelte"
  import Alert from "src/lib/Alert.svelte"

  import { onMount } from "svelte"
  import {
    elements,
    parties,
    partyIndex,
    party,
    createParty,
  } from "src/plugins/store"
  import { _, locale } from "svelte-i18n"

  onMount(() => {
    const newLanguage = $party?.language
    if (newLanguage) $locale = newLanguage
  })
</script>

<svelte:head>{$_("page_title")}</svelte:head>

<div
  bind:this={$elements.app}
  id="app"
  class="transition-all duration-300 fixed w-full h-full left-0 bg-white rounded-t-lg overflow-y-auto dark:bg-black"
>
  <LayoutTop />
  <Main />
  <LayoutBottom />
</div>
<SheetParties />
<SheetParty />
<SheetBill />
<SheetLog />
<SheetShare />
<Alert />

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {
    html,
    body {
      @apply h-full bg-black dark:text-[#AAA];
    }

    html {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    html::-webkit-scrollbar {
      display: none;
    }

    h1 {
      @apply text-5xl font-bold my-5;
    }
    h2 {
      @apply text-3xl font-semibold my-3;
    }
    h3 {
      @apply text-2xl font-semibold my-2;
    }

    input {
      @apply bg-transparent dark:placeholder-[#555];
    }

    input[type="number"] {
      @apply bg-transparent dark:placeholder-[#555];
    }

    select {
      direction: rtl;
    }

    select:active {
      direction: ltr;
    }

    @media screen and (max-width: 767px) {
      body {
        font-size: 17px;
      }
    }

    @media screen and (min-width: 768px) {
      body {
        font-size: 19px;
      }
    }

    .keep-all {
      word-break: keep-all;
    }

    /**
      Noto Sans KR
    */
    @font-face {
      font-family: Noto Sans KR;
      font-weight: 100;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100&display=swap)
        format("woff");
    }

    @font-face {
      font-family: Noto Sans KR;
      font-weight: 300;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&display=swap)
        format("woff");
    }

    @font-face {
      font-family: Noto Sans KR;
      font-weight: 400;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400&display=swap)
        format("woff");
    }

    @font-face {
      font-family: Noto Sans KR;
      font-weight: 500;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap)
        format("woff");
    }

    @font-face {
      font-family: Noto Sans KR;
      font-weight: 700;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&display=swap)
        format("woff");
    }

    @font-face {
      font-family: Noto Sans KR;
      font-weight: 900;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@900&display=swap)
        format("woff");
    }

    /**
      Noto Serif KR
    */
    @font-face {
      font-family: Noto Serif KR;
      font-weight: 200;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@200&display=swap)
        format("woff");
    }
    @font-face {
      font-family: Noto Serif KR;
      font-weight: 300;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@300&display=swap)
        format("woff");
    }
    @font-face {
      font-family: Noto Serif KR;
      font-weight: 400;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@400&display=swap)
        format("woff");
    }
    @font-face {
      font-family: Noto Serif KR;
      font-weight: 500;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@500&display=swap)
        format("woff");
    }
    @font-face {
      font-family: Noto Serif KR;
      font-weight: 600;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@600&display=swap)
        format("woff");
    }
    @font-face {
      font-family: Noto Serif KR;
      font-weight: 700;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@700&display=swap)
        format("woff");
    }
    @font-face {
      font-family: Noto Serif KR;
      font-weight: 900;
      src: url(https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@900&display=swap)
        format("woff");
    }
  }
</style>
