<div class="my-8">
  <div class="ml-6 mb-1 text-sm font-light">
    <slot name="top" />
  </div>
  <div
    class="bg-white rounded-lg flex flex-col space-y-3 mx-3 py-3 px-4 dark:bg-[#222] {$$props.class}"
  >
    <slot />
  </div>
  <div class="mt-2 ml-6 mb-1 text-sm font-light text-red-500">
    <slot name="bottom" />
  </div>
</div>
