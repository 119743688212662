<script>
  import { party } from "src/plugins/store"
  import currency from "src/utils/currency"
  import emitter from "src/plugins/emitter"

  function isExpertMode(bill) {
    return (
      bill.members.filter((member) => member.amount !== 0).length !== 1 ||
      bill.members.filter((member) => member.fixed !== null).length !== 0
    )
  }

  function isError(bill) {
    return (
      bill.amount !==
      bill.members.reduce((acc, cur) => {
        acc += cur.amount
        return acc
      }, 0)
    )
  }
</script>

{#if $party.bills.length > 0}
  <div class="grid grid-cols-2 gap-3 py-6">
    {#each $party.bills as bill (bill.title)}
      <div
        class="border rounded-lg p-1 cursor-pointer"
        class:border-4={isExpertMode(bill)}
        class:border-red-500={isError(bill)}
        on:click={() => emitter.emit("openSheetBill", bill)}
      >
        <div>
          {bill.title}
        </div>
        <div class="font-mono">
          {currency(bill.amount)}
        </div>
      </div>
    {/each}
  </div>
{/if}
