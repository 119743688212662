<script lang="ts">
  import Icon from "src/lib/Icon.svelte"
  import emitter from "src/plugins/emitter"
  import { elements } from "src/plugins/store"

  let deferredPrompt

  window.addEventListener("beforeinstallprompt", (e) => {
    deferredPrompt = e
  })

  async function install() {
    if (deferredPrompt !== null) {
      deferredPrompt.prompt()
      const { outcome } = await deferredPrompt.userChoice
      if (outcome === "accepted") {
        deferredPrompt = null
      }
    }
  }
</script>

<div
  bind:this={$elements.layoutTop}
  id="top"
  class="transition-all duration-300 sticky z-10 left-0 top-0 w-full h-12 rounded-t-lg bg-white bg-opacity-90 dark:bg-black dark:bg-opacity-[85%]"
>
  <div class="flex justify-between px-3 pt-3">
    <div on:click={install} class="font-semibold mt-.5">HAMPAY BETA</div>
    <div on:click={() => emitter.emit("openSheetParty")}>
      <Icon name="edit_note_black_24dp" pointer />
    </div>
  </div>
</div>
