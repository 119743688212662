<script type="ts">
  import Icon from "src/lib/Icon.svelte"
  import emitter from "src/plugins/emitter"
</script>

<div
  id="bottom"
  class="transition-all fixed w-full h-16 left-0 bottom-0 bg-white bg-opacity-90 dark:bg-black dark:bg-opacity-[85%]"
>
  <div class="flex justify-between px-3 pb-3">
    <div on:click={() => emitter.emit("openSheetParties")}>
      <Icon name="menu_black_24dp" pointer />
    </div>
    <div on:click={() => emitter.emit("openSheetBill")}>
      <Icon name="add_circle_black_24dp" pointer />
    </div>
    <div on:click={() => emitter.emit("openSheetShare")}>
      <Icon name="share_black_24dp" pointer />
    </div>
  </div>
</div>
