import LZString from "lz-string";
import { getLocaleFromNavigator } from "svelte-i18n";
import { currencies } from "src/plugins/i18n";
function hampressV2(input) {
    // Custom Optimization
    let result = {
        ...input,
        members: input.members.map((member) => member.name),
        bills: input.bills.map((bill) => ({
            ...bill,
            members: bill.members.map((member) => {
                if (member.amount === 0 &&
                    member.attend === true &&
                    member.fixed === null)
                    return {};
                if (member.amount > 0 &&
                    member.attend === true &&
                    member.fixed === null)
                    return { amount: member.amount };
                return {
                    amount: member.amount === 0 ? "" : member.amount,
                    attend: member.attend === true ? "" : member.attend,
                    fixed: member.fixed === null ? "" : member.fixed, // if null : ""
                };
            }),
        })),
    };
    // Remove Keys
    result = recursiveRemoveKeys(result);
    // Remove Quotes
    result = JSON.stringify(result).replace(/\"/g, "").replace(/\$\,/g, "");
    console.log(result);
    return result;
}
function dehampressV2(input) {
    let result;
    // Restore Quotes
    result = input
        .replace(/\[(?!\[)/g, `["`)
        .replace(/([^\]])\]/g, `$1"]`)
        .replace(/\,(?!\[)/g, `","`)
        .replace(/([^\]])\,\[/g, `$1",[`)
        .replace(/\]","/g, `],"`);
    console.log(result);
    // Parse
    result = JSON.parse(result);
    console.log(result);
    if (result[3] === undefined)
        throw "Sould use dehampressV1";
    // Restore Keys and Optimization
    result = {
        title: result[0],
        members: result[1].map((name) => ({ name })),
        bills: result[2].map((bill) => ({
            title: bill[0],
            amount: Number(bill[1]),
            members: bill[2].map((member, index) => ({
                name: result[1][index],
                amount: member[0] === "" ? 0 : Number(member[0]) || 0,
                attend: member[1] === "0" ? false : true,
                fixed: member[2] === "" ? null : Number(member[2]) || null,
            })),
        })),
        language: result[3] || undefined,
        currency: result[4] || undefined,
    };
    return result;
}
function recursiveRemoveKeys(input) {
    return Object.values(input).reduce((acc, value) => {
        if (typeof value === "string")
            acc.push(value);
        else if (typeof value === "number")
            acc.push(value);
        else if (typeof value === "boolean")
            acc.push(Number(value));
        else
            acc.push(recursiveRemoveKeys(value));
        return acc;
    }, []);
}
/**
 * Compress Object for Hampay app
 * @param input
 * @deprecated
 */
function hampress(input) {
    const indexed = {
        ...input,
        members: input.members.reduce((acc, elm) => {
            acc = [...acc, elm.name];
            return acc;
        }, []),
    };
    return JSON.stringify(arraify(indexed))
        .replace(/\"/g, "")
        .replace(/\$\,/g, "");
}
/**
 * Deep Interate on object
 * @param input
 * @deprecated
 */
function arraify(input) {
    return Object.entries(input).map(([key, value]) => {
        if (key === "indexName")
            return value;
        if (key === "name")
            return "$";
        if (value === null)
            return "";
        if (typeof value === "object")
            return arraify(value || {});
        if (typeof value === "number" && value === 0)
            return "";
        if (typeof value === "boolean")
            return value === true ? "1" : "";
        return value;
    });
}
/**
 * Decompress hampressed
 * @param input
 */
function dehampress(input) {
    const handledString = input
        .replace(/\[(?!\[)/g, `["`)
        .replace(/([^\]])\]/g, `$1"]`)
        .replace(/\,(?!\[)/g, `","`)
        .replace(/([^\]])\,\[/g, `$1",[`);
    const parsed = JSON.parse(handledString);
    const newLocale = getLocaleFromNavigator();
    const handledObject = {
        title: parsed[0],
        members: [...parsed[1].reduce((acc, cur) => [...acc, { name: cur }], [])],
        bills: [
            ...parsed[2].reduce((acc, cur) => [
                ...acc,
                {
                    title: cur[0],
                    amount: Number(cur[1]),
                    members: cur[2].reduce((acc, cur, idx) => [
                        ...acc,
                        {
                            name: parsed[1][idx],
                            amount: Number(cur[0]),
                            attend: cur[1] === "1" ? true : false,
                            fixed: cur[2] === "" ? null : Number(cur[2]),
                        },
                    ], []),
                },
            ], []),
        ],
        language: getLocaleFromNavigator(),
        currency: currencies.find((currency) => currency.languages.includes(newLocale))?.value,
    };
    return handledObject;
}
export function compress(input) {
    return LZString.compressToEncodedURIComponent(hampressV2(input));
}
export function decompress(input) {
    try {
        return dehampressV2(LZString.decompressFromEncodedURIComponent(input));
    }
    catch {
        return dehampress(LZString.decompressFromEncodedURIComponent(input));
    }
}
