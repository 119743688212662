import { get, derived, writable } from "svelte/store";
import { init, _, getLocaleFromNavigator } from "svelte-i18n";
import { currencies } from "src/plugins/i18n";
import { decompress } from "src/utils/hampress";
export const elements = writable({});
function loadPartyFromQuery() {
    try {
        if (!window.location.href.includes("?p="))
            return null;
        const compressed = window.location.href.split("?p=")[1];
        const decompressed = decompress(compressed);
        if (!window.location.href.includes("localhost"))
            window.history.pushState(null, "", "/");
        else
            window.history.pushState(null, "", "/");
        return decompressed;
    }
    catch (e) {
        alert(get(_)("init.phrase_invalid_url"));
        window.history.pushState(null, "", "/");
        return null;
    }
}
export const createParty = (title) => {
    const newLocale = getLocaleFromNavigator();
    init({
        fallbackLocale: "en",
        initialLocale: newLocale,
    });
    return {
        title: title || get(_)("new_party"),
        members: [],
        bills: [],
        language: newLocale,
        currency: currencies.find((currency) => currency.languages.includes(newLocale))?.value,
    };
};
let initPartyIndex = JSON.parse(localStorage.getItem("partyIndex")) || 0;
const initParties = () => {
    let result = [];
    const partyLocalStorage = JSON.parse(localStorage.getItem("parties"));
    const partyLoadedFromQuery = loadPartyFromQuery();
    // Local storage
    if (partyLocalStorage)
        result = partyLocalStorage;
    // URI Query
    if (partyLoadedFromQuery) {
        if (result.map((party) => party.title).includes(partyLoadedFromQuery.title)) {
            if (window.confirm(get(_)("init.phrase_duplicate_query", {
                values: { title: partyLoadedFromQuery.title },
            }))) {
                const index = result.findIndex((party) => party.title === partyLoadedFromQuery.title);
                result.splice(index, 1);
            }
            else {
                partyLoadedFromQuery.title = rename(result, partyLoadedFromQuery.title);
            }
        }
        result = [...result, partyLoadedFromQuery];
        initPartyIndex = result.length - 1;
    }
    // Empty -> New Party
    if (result.length === 0)
        result = [createParty()];
    return result;
};
function rename(parties, title) {
    if (parties.map((party) => party.title).includes(title))
        return rename(parties, title + "_new");
    else
        return title;
}
export const parties = writable(initParties());
export const partyIndex = writable(initPartyIndex);
export const party = derived([parties, partyIndex], ([$parties, $partyIndex]) => {
    return $parties[$partyIndex];
});
export const result = derived([party], ([$party]) => {
    if (!$party)
        return [];
    console.warn("calculating...");
    /**
     * 빚
     */
    const debts = $party.members.reduce((acc, member) => {
        acc[member.name] = 0;
        return acc;
    }, {});
    for (const bill of $party.bills) {
        // 낸사람 계산
        bill.members.forEach((member) => {
            if (member.amount > 0)
                debts[member.name] -= Number(member.amount);
        });
        // 고정 지출
        const fixedPayers = bill.members.filter((member) => member.fixed > 0);
        let sumOfFixedAmounts = 0;
        fixedPayers.forEach((member) => {
            debts[member.name] += Number(member.fixed);
            sumOfFixedAmounts += Number(member.fixed);
        });
        // 참석자 계산
        const attendees = bill.members.filter((member) => member.attend && member.fixed <= 0);
        const amount = (Number(bill.amount) - sumOfFixedAmounts) / attendees.length;
        attendees.forEach((member) => {
            debts[member.name] += amount;
        });
    }
    console.table(debts);
    /**
     * 최소 송금
     */
    let remits = [];
    // 쏙 들어가는 송금
    for (const debtor in debts) {
        if (debts[debtor].toFixed(2) <= 0)
            continue;
        const creditor = Object.keys(debts).find((creditor) => debts[creditor] < 0 &&
            Math.abs(debts[creditor]) >= Math.abs(debts[debtor]));
        if (!creditor)
            continue;
        console.log(debtor, debts[debtor], "->", creditor);
        debts[creditor] += debts[debtor];
        remits.push({ debtor, amount: debts[debtor], creditor });
        debts[debtor] = 0;
    }
    // 나누는 송금
    for (const debtor in debts) {
        if (debts[debtor].toFixed(2) <= 0)
            continue;
        const creditors = Object.keys(debts).filter((creditor) => debts[creditor] < 0);
        for (const creditor of creditors) {
            console.log(debtor, Math.abs(debts[creditor]), "->", creditor);
            remits.push({ debtor, amount: Math.abs(debts[creditor]), creditor });
            debts[debtor] += debts[creditor];
            debts[creditor] = 0;
        }
    }
    // 소수점 자르기
    for (const debtor in debts) {
        debts[debtor] = debts[debtor].toFixed(2);
    }
    remits = remits.map((remit) => ({
        ...remit,
        amount: Number(remit.amount.toFixed(2)),
    }));
    // log
    console.table(remits);
    // save
    saveLocalStorage();
    // return
    return remits;
});
export const saveLocalStorage = () => {
    localStorage.setItem("parties", JSON.stringify(get(parties)));
    localStorage.setItem("partyIndex", get(partyIndex).toString());
};
