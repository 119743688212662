<script lang="ts">
  import Button from "src/lib/Button.svelte"

  import { elements } from "src/plugins/store"
  import emitter from "src/plugins/emitter"

  let options: AlertOptions = {
    text: "",
    button: "OK",
  }

  emitter.on("alert", alert)
  function alert(opt: AlertOptions) {
    options = { ...options, ...opt }
    open()
  }

  function open() {
    $elements.alert.classList.remove("hidden")
  }

  function close() {
    $elements.alert.classList.add("hidden")
    options.text = ""
    options.button = "OK"
  }
</script>

<div
  bind:this={$elements.alert}
  class="fixed top-0 left-0 w-full h-full bg-black/25 hidden"
>
  <div
    class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
    min-w-[250px]
    rounded-lg
    px-6 py-3
    bg-white dark:bg-[#222]
    shadow-2xl
    "
  >
    <div class="my-3">
      {options.text}
    </div>
    <div class="text-right mt-3">
      <Button bg="bg-white dark:bg-[#222]" on:click={close}
        >{options.button}</Button
      >
    </div>
  </div>
</div>
