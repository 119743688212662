<script lang="ts">
  import { parties, partyIndex, createParty, elements } from "src/plugins/store"
  import Icon from "src/lib/Icon.svelte"
  import List from "src/lib/List.svelte"
  import Divider from "src/lib/Divider.svelte"
  import Overlay from "src/lib/Overlay.svelte"
  import Sheet from "src/lib/Sheet.svelte"
  import { _, locale } from "svelte-i18n"
  import { currencies } from "src/plugins/i18n"
  import emitter from "src/plugins/emitter"

  let inputNewPartyTitle: string = ""

  function selectParty(title) {
    // TODO: ANIMATION
    $partyIndex = $parties.findIndex((party) => party.title === title)

    // TODO: Refactory
    const newLanguage = $parties[$partyIndex].language
    if (newLanguage) $locale = newLanguage
  }

  function validateCreateParty(title) {
    if (title.trim().length === 0) return false
    return !$parties.map((party) => party.title).includes(title.trim())
  }

  function addParty(title) {
    if (validateCreateParty(title) === false) return

    $parties = [...$parties, createParty(title)]
    inputNewPartyTitle = ""
    selectParty(title)
  }

  emitter.on("openSheetParties", () => open())
  function open() {
    $elements.sheetParties.classList.replace("top-full", "top-1/4")
    $elements.sheetParties.classList.replace("h-full", "h-3/4")
    $elements.overlayParties.classList.replace("bg-opacity-0", "bg-opacity-50")
    $elements.overlayParties.classList.replace(
      "pointer-events-none",
      "pointer-events-auto"
    )
    $elements.app.classList.toggle("scale-95")
  }

  emitter.on("closeSheetParties", () => close())
  function close() {
    $elements.sheetParties.classList.replace("top-1/4", "top-full")
    $elements.sheetParties.classList.replace("h-3/4", "h-full")
    $elements.overlayParties.classList.replace("bg-opacity-50", "bg-opacity-0")
    $elements.overlayParties.classList.replace(
      "pointer-events-auto",
      "pointer-events-none"
    )
    $elements.app.classList.toggle("scale-95")
  }
</script>

<Overlay bind:that={$elements.overlayParties} on:click={close} />

<Sheet bind:that={$elements.sheetParties} position="bottom">
  {#if $parties.length > 0}
    <List>
      {#each $parties as party, index (party.title)}
        <div
          class="flex justify-between cursor-pointer"
          on:click={() => selectParty(party.title)}
        >
          {party.title}
          <Icon
            name={$partyIndex === index
              ? "check_circle_black_24dp"
              : "radio_button_unchecked_black_24dp"}
            class="w-6 h-6"
          />
        </div>
        {#if index !== $parties.length - 1}
          <Divider />
        {/if}
      {/each}
    </List>
  {/if}

  <List>
    <div class="flex justify-between">
      <input
        class="focus:outline-none caret-red-500"
        bind:value={inputNewPartyTitle}
        placeholder={$_("parties.add_new_party")}
        on:keypress={(e) => {
          if (e.key === "Enter") addParty(inputNewPartyTitle)
        }}
      />
      <div
        class={validateCreateParty(inputNewPartyTitle)
          ? "text-blue-500 cursor-pointer"
          : "text-gray-500"}
        on:click={() => addParty(inputNewPartyTitle)}
      >
        {$_("button.add")}
      </div>
    </div>
  </List>
</Sheet>
