<script lang="ts">
  import {
    elements,
    parties,
    partyIndex,
    party,
    createParty,
    saveLocalStorage,
  } from "src/plugins/store"
  import emitter from "src/plugins/emitter"
  import { languages, currencies } from "src/plugins/i18n"
  import { _, locale } from "svelte-i18n"
  import { get } from "svelte/store"
  import Icon from "src/lib/Icon.svelte"
  import Switch from "src/lib/Switch.svelte"
  import List from "src/lib/List.svelte"
  import Divider from "src/lib/Divider.svelte"
  import Overlay from "src/lib/Overlay.svelte"
  import Sheet from "src/lib/Sheet.svelte"
  import Bar from "src/lib/Bar.svelte"

  let original: Party = {
    title: "",
    members: [],
    bills: [],
    language: "",
    currency: "",
  }

  let input: Party = {
    title: "",
    members: [],
    bills: [],
    language: "",
    currency: "",
  }

  let partyTitleFocused = false
  let focusMemberIndex = null
  let inputNewPartyMember = ""

  let inputCurrency = "krw"

  let isSame
  $: isSame =
    JSON.stringify({ ...original, language: null }) ===
    JSON.stringify({ ...input, language: null })

  $: errorMemberNameNull = input.members.some(
    (member) => member.name.length === 0
  )

  $: errorMemberNameDuplicated =
    new Set(input.members.map((member) => member.name)).size !==
    input.members.map((member) => member.name).length

  $: errorPartyTitleNull = input.title.trim().length === 0

  $: errorPartyTitleDuplicated = $parties
    .map((party) => party.title)
    .filter((title) => title !== original.title)
    .includes(input.title)

  // Only for New Input Member Name
  $: errorNewMemberNameDuplicated = input.members.some(
    (member) => member.name === inputNewPartyMember
  )

  $: errorNewMemberNameNull = inputNewPartyMember.trim().length === 0

  // for save
  $: validForSave =
    !isSame &&
    !errorMemberNameDuplicated &&
    !errorMemberNameNull &&
    !errorPartyTitleDuplicated &&
    !errorPartyTitleNull

  $: validForAddMember =
    !errorNewMemberNameNull && !errorNewMemberNameDuplicated

  emitter.on("initSheetParty", () => init())
  function init() {
    original = JSON.parse(JSON.stringify($party))
    original.members = original.members.map((member, id) => ({ ...member, id }))
    input = JSON.parse(JSON.stringify($party))
    input.members = input.members.map((member, id) => ({ ...member, id }))
  }

  function addMember() {
    if (!validForAddMember) return
    const id = input.members.length
    input.members = [...input.members, { name: inputNewPartyMember.trim(), id }]
    input.bills = input.bills.map((bill) => ({
      ...bill,
      members: [
        ...bill.members,
        {
          name: inputNewPartyMember.trim(),
          amount: 0,
          fixed: null,
          attend: false,
        },
      ],
    }))
    inputNewPartyMember = ""
  }

  function onChangeEditMember() {
    input.bills = input.bills.map((bill) => ({
      ...bill,
      members: bill.members.map((member, index) => ({
        ...member,
        name: input.members[index].name,
      })),
    }))
  }

  function removeMember(index) {
    // 파티 멤버 삭제 input.members
    input.members.splice(index, 1)

    // 영수증 멤버 삭제 input.bills->members 멤버 삭제
    input.bills.map((bill) => ({
      ...bill,
      members: bill.members.splice(index, 1),
    }))

    input = input
  }

  function onChangeLanguage() {
    $locale = input.language
    $parties[$partyIndex].language = input.language
    saveLocalStorage()
  }

  function save() {
    if (!validForSave) return

    input.members = input.members.map((member) => ({ name: member.name }))

    $locale = input.language
    $parties[$partyIndex] = input
    close()

    saveLocalStorage()
  }

  function removeParty() {
    if (
      !window.confirm(
        $_("party.phrase_delete", { values: { title: input.title } })
      )
    )
      return

    $parties.splice($partyIndex, 1)
    if ($parties.length === 0) $parties = [createParty()]
    $partyIndex = $parties.length - 1
    saveLocalStorage()
    $parties = $parties
    close()
    emitter.emit("openSheetParties")
  }

  emitter.on("openSheetParty", () => open())
  function open() {
    init()
    $elements.sheetParty.classList.replace("-right-full", "right-[0%]")
    $elements.overlayParty.classList.replace("bg-opacity-0", "bg-opacity-50")
    $elements.overlayParty.classList.replace(
      "pointer-events-none",
      "pointer-events-auto"
    )
    $elements.app.classList.toggle("scale-95")
  }

  emitter.on("closeSheetParty", () => close())
  function close() {
    $elements.sheetParty.classList.replace("right-[0%]", "-right-full")
    $elements.overlayParty.classList.replace("bg-opacity-50", "bg-opacity-0")
    $elements.overlayParty.classList.replace(
      "pointer-events-auto",
      "pointer-events-none"
    )
    $elements.app.classList.toggle("scale-95")
  }
</script>

<Overlay bind:that={$elements.overlayParty} on:click={close} />

<Sheet bind:that={$elements.sheetParty} position="right">
  <!-- TOP -->
  <Bar>
    <div
      slot="left"
      class="cursor-pointer"
      class:text-blue-500={isSame}
      class:text-red-500={!isSame}
      on:click={close}
    >
      {isSame ? $_("button.back") : $_("button.cancel")}
    </div>
    <div slot="center">{$_("party.party")}</div>
    <div
      slot="right"
      class:text-blue-500={validForSave}
      class:cursor-pointer={validForSave}
      class:text-gray-500={!validForSave}
      on:click={save}
    >
      {$_("button.save")}
    </div>
  </Bar>

  <!-- 파티 제목 -->
  <List>
    <div slot="top">{$_("party.party_title")}</div>
    <div class="flex justify-between">
      <input
        id="party-title"
        class="focus:outline-none caret-red-500 flex-grow"
        class:placeholder-red-500={errorPartyTitleNull}
        bind:value={input.title}
        on:focus={() => {
          partyTitleFocused = true
        }}
        on:blur={() => {
          partyTitleFocused = false
        }}
        placeholder={$_("party.party_title")}
      />
      {#if partyTitleFocused === true && input.title.length > 0}
        <Icon
          name="cancel_black_24dp"
          class="w-5 h-5"
          pointer
          on:mousedown={() => {
            input.title = ""
            setTimeout(() => {
              document.querySelector("#party-title").focus()
            }, 0)
          }}
        />
      {/if}
    </div>
    <div slot="bottom">
      <div>
        {(errorPartyTitleDuplicated && $_("party.phrase_title_duplicate")) ||
          ""}
      </div>
    </div>
  </List>

  <!-- 멤버 설정 -->
  {#if input.members.length > 0 && input.members.some((member) => member.id !== undefined)}
    <List>
      <div slot="top">{$_("party.set_members")}</div>
      {#each input.members as member, index (member.id)}
        <div class="flex justify-between">
          <input
            class="focus:outline-none caret-red-500 flex-grow"
            on:focus={() => {
              focusMemberIndex = index
            }}
            on:blur={() => {
              focusMemberIndex = null
            }}
            bind:value={member.name}
            on:change={onChangeEditMember}
          />
          {#if focusMemberIndex === index}
            <span
              class="text-red-500 cursor-pointer"
              on:mousedown={removeMember(index)}
            >
              {$_("button.delete")}
            </span>
          {/if}
        </div>
        {#if index !== input.members.length - 1}
          <Divider />
        {/if}
      {/each}
      <div slot="bottom">
        <div>
          {(errorMemberNameNull && $_("party.phrase_invalid_member")) || ""}
        </div>
        <div>
          {(errorMemberNameDuplicated && $_("party.phrase_duplicate_member")) ||
            ""}
        </div>
      </div>
    </List>
  {/if}

  <List>
    <div class="flex justify-between">
      <input
        class="focus:outline-none caret-red-500 flex-grow"
        bind:value={inputNewPartyMember}
        placeholder={$_("party.add_new_member")}
        on:keypress={(e) => {
          if (e.key === "Enter") addMember()
        }}
      />
      <div
        class:text-gray-500={!validForAddMember}
        class:text-blue-500={validForAddMember}
        class:cursor-pointer={validForAddMember}
        on:click={addMember}
      >
        {$_("button.add")}
      </div>
    </div>
  </List>

  <!-- TODO: 기타 설정 -->
  <List>
    <div class="flex justify-between">
      <div>{$_("party.language")}</div>
      <select
        class="focus:outline-none bg-transparent font-mono text-right pr-1 appearance-none cursor-pointer"
        bind:value={input.language}
        on:change={onChangeLanguage}
      >
        {#each languages as language}
          <option value={language.value}>
            {language.display}
          </option>
        {/each}
      </select>
    </div>
    <Divider />
    <div class="flex justify-between">
      <div>{$_("party.currency")}</div>
      <select
        class="focus:outline-none bg-transparent font-mono text-right pr-1 appearance-none cursor-pointer"
        bind:value={input.currency}
      >
        {#each currencies as currency}
          <option value={currency.value} style="direction:ltr;">
            {currency.display}({currency.symbol})
          </option>
        {/each}
      </select>
    </div>
  </List>

  <List>
    <div class="flex justify-between">
      <div>{$_("party.delete_party")}</div>
      <div class="text-red-500 cursor-pointer" on:click={removeParty}>
        {$_("button.delete")}
      </div>
    </div>
  </List>
</Sheet>
