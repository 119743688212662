<script lang="ts">
  export let bg = ""
</script>

<div
  id={$$props.id}
  class={`${
    bg ? bg : "bg-gray-200 dark:bg-[#222]"
  } inline-block rounded-lg p-3 text-sm cursor-pointer hover:opacity-90 tracking-wide `}
  on:click
>
  <div>
    <slot />
  </div>
</div>
