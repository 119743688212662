<script lang="ts">
  export let checked = false
  export let disabled = false
</script>

<label class="switch-button">
  <input type="checkbox" bind:checked {disabled} />
  <span class="onoff-switch" />
</label>

<style>
  .switch-button {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;
  }
  .switch-button input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .onoff-switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .onoff-switch:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.4s;
    border-radius: 20px;
  }
  .switch-button input:checked + .onoff-switch {
    background-color: #000;
  }
  .switch-button input:checked + .onoff-switch:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
</style>
