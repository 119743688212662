<script lang="ts">
  import { result } from "src/plugins/store"
  import currency from "src/utils/currency"
  import { _ } from "svelte-i18n"
  import emitter from "src/plugins/emitter"
</script>

<div class="grid grid-cols-12 gap-2 py-6">
  <div class="col-span-4 font-bold">{$_("main.result.sender")}</div>
  <div class="col-span-4 font-bold text-right">
    {$_("main.result.amount")} →
  </div>
  <div class="col-span-4 font-bold text-right">
    {$_("main.result.receiver")}
  </div>
  <div class="col-span-12 border-b border-gray-400" />
  {#each $result as remit}
    <div
      class="col-span-4 cursor-pointer"
      on:click={() => emitter.emit("openSheetLog", remit.debtor)}
    >
      {remit.debtor}
    </div>
    <div class="col-span-4 text-right font-mono">
      {currency(remit.amount)}
    </div>
    <div
      class="col-span-4 text-right cursor-pointer"
      on:click={() => emitter.emit("openSheetLog", remit.creditor)}
    >
      {remit.creditor}
    </div>
    <div class="col-span-12 border-b dark:border-gray-700" />
  {/each}
</div>
