<script lang="ts">
  import {
    elements,
    parties,
    partyIndex,
    party,
    saveLocalStorage,
  } from "src/plugins/store"
  import Icon from "src/lib/Icon.svelte"
  import Switch from "src/lib/Switch.svelte"
  import List from "src/lib/List.svelte"
  import Divider from "src/lib/Divider.svelte"
  import Overlay from "src/lib/Overlay.svelte"
  import Sheet from "src/lib/Sheet.svelte"
  import Bar from "src/lib/Bar.svelte"
  import { _ } from "svelte-i18n"
  import emitter from "src/plugins/emitter"

  let mode: "add" | "edit" = undefined
  $: isAddMode = mode === "add"

  let originalExpertMode = false
  let expertMode = false

  let original: Bill = {
    title: "",
    amount: null,
    members: $party.members.map((member) => ({
      ...member,
      amount: 0,
      attend: true,
      fixed: null,
    })),
  }
  let input: Bill = {
    title: "",
    amount: null,
    members: $party.members.map((member) => ({
      ...member,
      amount: 0,
      attend: true,
      fixed: null,
    })),
  }

  let isSame
  $: isSame =
    JSON.stringify(original) === JSON.stringify(input) &&
    originalExpertMode === expertMode

  let inputPayer
  $: inputPayer = null

  /**
   * Init Add Bill
   */
  function initAdd() {
    mode = "add"
    input = {
      title: "",
      amount: null,
      members: $party.members.map((member) => ({
        ...member,
        amount: 0,
        attend: true,
        fixed: null,
      })),
    }
    inputPayer = null
  }

  /**
   * Init Edit Bill
   */
  function initEdit(bill) {
    mode = "edit"
    original = JSON.parse(JSON.stringify(bill))
    input = JSON.parse(JSON.stringify(bill))

    if (
      input.members.filter((member) => member.amount !== 0).length !== 1 ||
      input.members.filter((member) => member.fixed !== null).length !== 0
    ) {
      originalExpertMode = expertMode = true
    } else {
      originalExpertMode = expertMode = false
      inputPayer = input.members.find((member) => member.amount !== 0).name
    }
  }

  let valid
  $: valid = () => {
    return !(
      errorTitleEmpty ||
      errorTitleDuplicate ||
      errorAmountEmpty ||
      errorAmountNotNumber ||
      errorPayerNull ||
      errorSumOfMemberAmounts ||
      errorMemberAmountsNotNumber ||
      errorMemberFixedsNotNumber
    )
  }

  $: errorTitleEmpty = input.title.trim().length === 0

  $: errorTitleDuplicate = isAddMode
    ? $party.bills.some((bill) => bill.title === input.title)
    : $party.bills.some(
        (bill) => bill.title === input.title && original.title !== input.title
      )

  $: errorAmountEmpty = input.amount === null

  $: errorAmountNotNumber = Number.isNaN(input.amount)

  $: errorSumOfMemberAmounts =
    input.members.reduce((acc, cur) => acc + cur.amount, 0) !== input.amount

  $: errorMemberAmountsNotNumber = input.members.some((member) =>
    Number.isNaN(member.amount)
  )

  $: errorPayerNull = !expertMode && inputPayer === null

  $: errorMemberFixedsNotNumber =
    expertMode && input.members.some((member) => Number.isNaN(member.fixed))

  function onInputAmount() {
    input.members = input.members.map((member) =>
      member.name === inputPayer
        ? { ...member, amount: input.amount }
        : { ...member, amount: 0 }
    )
  }

  function addBill() {
    if (valid() === false) return

    $parties[$partyIndex].bills = [...$parties[$partyIndex].bills, input]

    close()
  }

  function editBill() {
    if (!valid() || isSame) return

    $parties[$partyIndex].bills = $parties[$partyIndex].bills.map((bill) =>
      bill.title === original.title ? input : bill
    )

    close()
  }

  function removeBill() {
    if (
      !window.confirm(
        $_("bill.phrase_delete", { values: { title: input.title } })
      )
    )
      return

    $parties[$partyIndex].bills = $parties[$partyIndex].bills.filter(
      (bill) => bill.title !== original.title
    )

    saveLocalStorage()
    close()
  }

  function openPartySheet() {
    close()
    emitter.emit("openSheetParty")
  }

  emitter.on("openSheetBill", (bill) => open(bill))
  function open(bill) {
    if ($party.members.length === 0) {
      emitter.emit("alert", { text: "파티 멤버를 먼저 추가해 주세요." }) // TODO: i18n
      return
    }

    if (bill?.title && bill?.amount && bill?.members) {
      initEdit(bill)
    } else {
      initAdd()
    }

    // $elements.layoutTop.classList.replace("bg-white", "bg-gray-400")
    // $elements.app.classList.replace("bg-white", "bg-gray-400")
    $elements.sheetBill.classList.replace("top-full", "top-[5%]")
    $elements.sheetBill.classList.replace("h-full", "h-[95%]")
    $elements.overlayBill.classList.replace("bg-opacity-0", "bg-opacity-50")
    $elements.overlayBill.classList.replace(
      "pointer-events-none",
      "pointer-events-auto"
    )
    $elements.app.classList.toggle("scale-95")
  }

  emitter.on("closeSheetBill", () => close())
  function close() {
    // $elements.layoutTop.classList.replace("bg-gray-400", "bg-white")
    // $elements.app.classList.replace("bg-gray-400", "bg-white")
    $elements.sheetBill.classList.replace("top-[5%]", "top-full")
    $elements.sheetBill.classList.replace("h-[95%]", "h-full")
    $elements.overlayBill.classList.replace("bg-opacity-50", "bg-opacity-0")
    $elements.overlayBill.classList.replace(
      "pointer-events-auto",
      "pointer-events-none"
    )
    $elements.app.classList.toggle("scale-95")
  }
</script>

<Overlay bind:that={$elements.overlayBill} on:click={close} />

<Sheet bind:that={$elements.sheetBill} position="bottom">
  <!-- START TOP -->
  {#if isAddMode === true}
    <Bar>
      <div slot="left" class="text-blue-500 cursor-pointer" on:click={close}>
        {$_("button.cancel")}
      </div>
      <div slot="center">{$_("bill.bill")}</div>
      <div
        slot="right"
        class:text-gray-500={!valid()}
        class:text-blue-500={valid()}
        class:cursor-pointer={valid()}
        on:click={addBill}
      >
        {$_("button.add")}
      </div>
    </Bar>
  {:else}
    <Bar>
      <div
        slot="left"
        class="text-blue-500 cursor-pointer"
        class:text-blue-500={isSame}
        class:text-red-500={!isSame}
        on:click={close}
      >
        {isSame ? $_("button.back") : $_("button.cancel")}
      </div>
      <div slot="center">{$_("bill.bill")}</div>
      <div
        slot="right"
        class:text-gray-500={!valid() || isSame}
        class:text-blue-500={valid() && !isSame}
        class:cursor-pointer={valid() && !isSame}
        on:click={editBill}
      >
        {$_("button.edit")}
      </div>
    </Bar>
  {/if}
  <!-- END TOP -->

  {#if $party.members.length === 0}
    <div class="text-center text-red-500" on:click={openPartySheet}>
      {$_("bill.phrase_set_party_member_first")}
    </div>
  {/if}

  <!-- BILL INFO -->
  <List class={$party.members.length === 0 ? "opacity-25" : ""}>
    <input
      class="focus:outline-none caret-red-500"
      class:placeholder-red-500={!isAddMode && errorTitleEmpty}
      bind:value={input.title}
      placeholder={$_("bill.bill_title")}
    />
    <Divider />
    <input
      type="number"
      class="focus:outline-none caret-red-500"
      class:placeholder-red-500={!isAddMode && errorAmountEmpty}
      class:text-red-500={errorAmountNotNumber}
      bind:value={input.amount}
      on:input={onInputAmount}
      placeholder={$_("bill.amount")}
    />
    <div slot="bottom">
      <div>
        {(errorTitleDuplicate && $_("bill.phrase_duplicate_bill_title")) || ""}
      </div>
    </div>
  </List>

  <!-- ADVANCED MODE -->
  <List class={$party.members.length === 0 ? "opacity-25" : ""}>
    <div class="flex justify-between">
      <div>{$_("bill.expert_mode")}</div>
      <div>
        <Switch
          bind:checked={expertMode}
          disabled={$party.members.length === 0}
        />
      </div>
    </div>
  </List>

  <!-- DETAILS -->
  {#if $party.members.length !== 0}
    {#if expertMode === false}
      <List>
        <div slot="top">
          {$_("bill.payer_attendees")}
        </div>
        {#each input.members as member, index (member.name)}
          <div class="flex">
            <div
              on:click={() => {
                if (
                  input.members.filter((member) => member.amount !== 0).length >
                    1 ||
                  input.members.filter((member) => member.fixed !== null)
                    .length !== 0
                )
                  if (!window.confirm($_("bill.phrase_reset_expert_mode")))
                    return
                inputPayer = member.name
                input.members = input.members.map((member) =>
                  member.name === inputPayer
                    ? { ...member, amount: input.amount, fixed: null }
                    : { ...member, amount: 0, fixed: null }
                )
              }}
              class="mr-3"
            >
              <Icon
                fill="#3B82F6"
                name={inputPayer === member.name
                  ? "check_circle_black_24dp"
                  : "radio_button_unchecked_black_24dp"}
                pointer
              />
            </div>
            <div
              on:click={() => {
                member.attend = !member.attend
              }}
              class="mr-3"
            >
              <Icon
                fill="#10B99F"
                name={member.attend
                  ? "check_box_black_24dp"
                  : "check_box_outline_blank_black_24dp"}
                pointer
              />
            </div>
            <div class="flex">
              <div>
                {#if inputPayer === member.name}
                  <div class="text-blue-500">
                    {$_("bill.phrase_paid", {
                      values: { name: member.name },
                    })}
                  </div>
                {/if}
                {#if member.attend === true}
                  <div class="text-green-500">
                    {$_("bill.phrase_attended", {
                      values: { name: member.name },
                    })}
                  </div>
                {:else}
                  <div class="text-red-500">
                    {$_("bill.phrase_not_attended", {
                      values: { name: member.name },
                    })}
                  </div>
                {/if}
              </div>
            </div>
          </div>
          {#if index !== input.members.length - 1}
            <Divider class="ml-20" />
          {/if}
        {/each}
      </List>
    {:else}
      <div
        class="bg-white dark:bg-[#222] mx-3 p-3 mb-8 mt-14 rounded-lg grid grid-cols-12 gap-3"
      >
        <div class="col-span-2">
          {$_("bill.name")}
        </div>
        <div class="col-span-4 text-right">
          {$_("bill.paid")}
        </div>
        <div class="col-span-4 text-right">
          {$_("bill.pay")}
        </div>
        <div class="col-span-2 text-right">
          {$_("bill.attended")}
        </div>
        <div class="col-span-12 border-b border-gray-400" />
        {#each input.members as member, index (member.name)}
          <div class="col-span-2 self-center">
            {member.name}
          </div>
          <input
            type="number"
            class="col-span-4 text-right focus:outline-none caret-red-500 place-self-stretch"
            bind:value={member.amount}
            placeholder="0"
          />
          <input
            type="number"
            class="col-span-4 text-right focus:outline-none caret-red-500 place-self-stretch"
            bind:value={member.fixed}
            placeholder={member.attend
              ? String(
                  (input.amount -
                    input.members.reduce((acc, cur) => {
                      return (acc += cur.fixed || 0)
                    }, 0)) /
                    input.members.filter(
                      (member) =>
                        member.fixed === null && member.attend === true
                    ).length
                )
              : "0"}
          />
          <div
            class="col-span-2 place-self-end"
            on:click={() => {
              member.attend = !member.attend
            }}
          >
            <Icon
              fill="#10B99F"
              name={member.attend
                ? "check_box_black_24dp"
                : "check_box_outline_blank_black_24dp"}
              pointer
            />
          </div>
          {#if index !== input.members.length - 1}
            <div class="col-span-12 border-b dark:border-gray-700" />
          {/if}
        {/each}
      </div>
    {/if}
  {/if}
  <div class="-mt-6 mb-16 ml-6 text-sm font-light text-red-500">
    <div>
      {#if $party.members.length !== 0}
        {(errorPayerNull && $_("bill.phrase_no_payer")) || ""}
      {/if}
    </div>
    <div>
      {(expertMode &&
        input.amount &&
        errorSumOfMemberAmounts &&
        $_("bill.phrase_invalid_sum_of_member_amounts")) ||
        ""}
    </div>
    <div>
      {(errorMemberAmountsNotNumber && $_("bill.phrase_invalid_paid_amount")) ||
        ""}
    </div>
    <div>
      {(errorMemberFixedsNotNumber && $_("bill.phrase_invalid_pay_amount")) ||
        ""}
    </div>
  </div>

  {#if isAddMode === false}
    <List>
      <div class="flex justify-between">
        <div>{$_("bill.delete_bill")}</div>
        <div class="text-red-500" on:click={removeBill}>
          {$_("button.delete")}
        </div>
      </div>
    </List>
  {/if}
</Sheet>
