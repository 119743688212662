import { get } from "svelte/store";
import { locale } from "svelte-i18n";
import { party } from "src/plugins/store";
export default function (value) {
    const currency = get(party).currency;
    if (currency !== "none")
        return new Intl.NumberFormat(get(locale) || "en", {
            style: "currency",
            currency: currency,
            currencyDisplay: "narrowSymbol",
        }).format(value);
    else
        return new Intl.NumberFormat(get(locale) || "en", {
            style: "decimal",
        }).format(value);
}
